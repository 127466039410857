import * as React from 'react';
import { IAppSettings } from '../types';

const defaultSettings: IAppSettings = {
	apiBase: '',
	postType: 'post',
	postTypeLabel: 'Post',
	postsPerPage: 12,
	filtersToShow: [],
	showSearch: true,
	layout: 'default',
	searchTitle: 'Search by keyword',
	searchTerm: '',
	initSelectedTax: {},
	wideCardType: false,
};

const SettingsContext = React.createContext<IAppSettings>(defaultSettings);

const useSettingsContext = () => React.useContext(SettingsContext);

export { SettingsContext, useSettingsContext };
