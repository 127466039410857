import * as React from 'react';
import FiltersBar from '../FiltersBar';
import LoadMore from '../LoadMore';
import NoResults from '../NoResults';
import { FiltersProvider } from '../../contexts/filters';
import { PostsProvider } from '../../contexts/posts';
import Search from '../filters/Search';
import Filters from '../Filters';
import PostsGrid from '../PostsGrid';
import Posts from '../Posts';

const LayoutDefault = () => {
	return (
		<>
			<FiltersProvider>
				<FiltersBar>
					<Search />
					<Filters />
				</FiltersBar>
				<PostsProvider>
					<Posts>
						<PostsGrid posts={[]} />
						<LoadMore page={0} maxNumPages={0} />
						<NoResults posts={[]} />
					</Posts>
				</PostsProvider>
			</FiltersProvider>
		</>
	);
};

LayoutDefault.displayName = 'LayoutDefault';

export default LayoutDefault;
