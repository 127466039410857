import * as React from 'react';

interface IProps {
	page: number;
	maxNumPages: number;
	setPage?: (page: number) => void;
}

const LoadMore = ({ page, maxNumPages, setPage }: IProps) => {
	if (maxNumPages > page) {
		const handleOnClick = () => {
			if (setPage) {
				setPage(page + 1);
			}
		};

		return (
			<div className="pfa-load-more">
				<button className="pfa-load-more__button" onClick={handleOnClick}>
					Load More
				</button>
			</div>
		);
	}

	return <></>;
};

LoadMore.displayName = 'LoadMore';

export default LoadMore;
