import * as React from 'react';
import { useFiltersContext } from '../contexts/filters';
import { useSettingsContext } from '../contexts/settings';
import Select from './filters/Select';

const Filters = () => {
	const { tax, clearFilters } = useFiltersContext();
	const { postTypeLabel } = useSettingsContext();

	const taxEntries = Object.entries(tax);
	if (taxEntries.length) {
		return (
			<div className="pfa-filter border-top animated-border-top animated-border-top--from-left border-bottom animated-border-bottom animated-border-bottom--from-right">
				<div className="container-fluid">
					<div className="pfa-filters__tax">
						<div className="pfa-filters-bar__title">{`Filter ${postTypeLabel}`}</div>

						<button onClick={clearFilters} className="pfa-filters-bar__clear">
							Clear Filters
						</button>

						<div className="pfa-filters__tax-list">
							{taxEntries.map(([key, taxonomy]) => {
								return <Select taxonomy={taxonomy} key={key} />;
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return <></>;
};

Filters.displayName = 'Filters';

export default Filters;
