import * as React from 'react';
import { useReducer, useEffect, useRef } from 'react';
import { useFiltersContext } from './filters';
import { useSettingsContext } from './settings';
import getPosts from '../externals/getPosts';
import { IPost } from '../types';

const actions = {
	SET_POSTS: 'SET_POSTS',
	ADD_POSTS: 'ADD_POSTS',
	SET_PAGE: 'SET_PAGE',
	SET_LOADING: 'SET_LOADING',
	SET_LOADING_HEIGHT: 'SET_LOADING_HEIGHT',
};

interface IPostsState {
	posts: Array<IPost>;
	page: number;
	maxNumPages: number;
	foundPosts: number;
	loading: boolean;
	loadingHeight: number | null;
}

interface IReducerAction {
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload?: any;
}

const reducer = (state: IPostsState, action: IReducerAction) => {
	switch (action.type) {
		case actions.SET_POSTS: {
			return {
				...state,
				posts: action.payload.posts,
				page: 1,
				maxNumPages: action.payload.max_num_pages,
				foundPosts: action.payload.found_posts,
				loading: false,
			};
		}

		case actions.ADD_POSTS: {
			return {
				...state,
				posts: [...state.posts, ...action.payload.posts],
				loading: false,
			};
		}

		case actions.SET_PAGE: {
			return {
				...state,
				page: action.payload.page,
			};
		}

		case actions.SET_LOADING: {
			return {
				...state,
				loading: action.payload.isLoading,
			};
		}

		case actions.SET_LOADING_HEIGHT: {
			return {
				...state,
				loadingHeight: action.payload.loadingHeight,
			};
		}

		default: {
			return state;
		}
	}
};

interface IPostsContext {
	posts: Array<IPost>;
	page: number;
	maxNumPages: number;
	foundPosts: number;
	loading: boolean;
	loadingHeight: number | null;
	setPage: (page: number) => void;
	setLoading: (isLoading: boolean) => void;
	setLoadingHeight: (loadingHeight: number | null) => void;
}

const PostsContext = React.createContext<IPostsContext>({} as IPostsContext);

interface IProps {
	children: React.ReactNode;
}

const PostsProvider = ({ children }: IProps) => {
	const initialState = {
		posts: [],
		page: 1,
		maxNumPages: 1,
		foundPosts: 1,
		loadingHeight: null,
		loading: false,
	};

	const [state, dispatch] = useReducer(reducer, initialState);
	const settings = useSettingsContext();
	const { selectedTax, searchQuery } = useFiltersContext();

	useEffect(() => {
		dispatch({ type: actions.SET_LOADING, payload: { isLoading: true } });

		const fetchData = async () => {
			const response = await getPosts(settings, {
				selectedTax,
				searchQuery,
			});
			dispatch({ type: actions.SET_POSTS, payload: response });
		};

		// eslint-disable-next-line no-console
		fetchData().catch(console.error);
	}, [selectedTax, searchQuery]);

	// On page change, add posts. Prevent adding on remounts by storing a ref of prev page.
	const prevPage = useRef([state.page, selectedTax]);
	useEffect(() => {
		if (1 === state.page || prevPage.current === state.page) {
			return;
		}

		const fetchData = async () => {
			const response = await getPosts(
				settings,
				{
					selectedTax,
					searchQuery,
				},
				state.page
			);
			dispatch({ type: actions.ADD_POSTS, payload: response });
			prevPage.current = [state.page, selectedTax];
		};

		// eslint-disable-next-line no-console
		fetchData().catch(console.error);
	}, [state.page]);

	const value = {
		posts: state.posts,
		page: state.page,
		maxNumPages: state.maxNumPages,
		loading: state.loading,
		loadingHeight: state.loadingHeight,
		foundPosts: state.foundPosts,
		setPage: (page: number) => {
			dispatch({ type: actions.SET_PAGE, payload: { page } });
		},
		setLoading: (isLoading: boolean) => {
			dispatch({ type: actions.SET_PAGE, payload: { isLoading } });
		},
		setLoadingHeight: (loadingHeight: number | null) => {
			dispatch({ type: actions.SET_LOADING_HEIGHT, payload: { loadingHeight } });
		},
	};

	return <PostsContext.Provider value={value}>{children}</PostsContext.Provider>;
};

const usePostsContext = () => React.useContext(PostsContext);

export { PostsProvider, usePostsContext };
