import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { IAppSettings } from './types';

const elements = document.querySelectorAll('.post-filters-archive');

elements.forEach((element) => {
	const root = createRoot(element);

	const apiBase = element.getAttribute('data-api-base');
	const postType = element.getAttribute('data-post-type');
	const postTypeLabel = element.getAttribute('data-post-type-label');
	const postsPerPage = element.getAttribute('data-posts-per-page');
	const filtersToShow = element.getAttribute('data-filters-to-show');
	const showSearch = element.getAttribute('data-show-search');
	const layout = element.getAttribute('data-layout');
	const searchTitle = element.getAttribute('data-search-title');
	const searchTerm = element.getAttribute('data-search-term');
	const selectedTax = element.getAttribute('data-selected-tax');
	const wideCardType = element.getAttribute('data-wide-card-type');

	const settings: IAppSettings = {
		apiBase: apiBase ?? '',
		postType: postType ?? 'post',
		postTypeLabel: postTypeLabel ?? 'Post',
		postsPerPage: null !== postsPerPage ? +postsPerPage : 12,
		filtersToShow: filtersToShow?.length ? filtersToShow.split(',') : [],
		showSearch: !!showSearch,
		layout: layout ?? 'default',
		searchTitle: searchTitle ?? 'Search by keyword',
		searchTerm: searchTerm ?? '',
		initSelectedTax: null !== selectedTax ? JSON.parse(selectedTax) : {},
		wideCardType: !!wideCardType,
	};

	if (settings.apiBase.length) {
		root.render(
			<React.StrictMode>
				<App settings={settings} />
			</React.StrictMode>
		);
	}
});
