import * as React from 'react';
import { useFiltersContext } from '../contexts/filters';
import { useSettingsContext } from '../contexts/settings';

interface IProps {
	children: React.ReactNode;
}

const FiltersBar = ({ children }: IProps) => {
	const { tax } = useFiltersContext();
	const settings = useSettingsContext();

	const taxEntries = Object.entries(tax);

	if (taxEntries.length || settings.showSearch) {
		return <div className="pfa-filters-bar">{children}</div>;
	}

	return <></>;
};

FiltersBar.displayName = 'FiltersBar';

export default FiltersBar;
