import * as React from 'react';
import sanitizeHtml from 'sanitize-html';
import formatAuthors from '../../utils/formatAuthors';
import formatDate from '../../utils/formatDate';
import { IPost } from '../../types';

interface IProps {
	post: IPost;
}

interface IPostTypes {
	insight: string;
	'team-member': string;
	spotlight: string;
	post: string;
}

const postTypes: IPostTypes = {
	insight: 'Insight',
	'team-member': 'Team Member',
	spotlight: 'Collection',
	post: 'News',
};

const Wide = ({ post }: IProps) => {
	const postType = post.post_type as keyof IPostTypes;
	const date = new Date(post.post_date);
	const dateString = formatDate(date);
	const authorsString = formatAuthors(post.authors);
	const maxExcerptLength = 200;

	const target = 'post' === post.post_type ? '_blank' : '_self';

	const excerptContent =
		post.post_excerpt &&
		post.post_excerpt.length > maxExcerptLength &&
		'post' !== post.post_type
			? `${post.post_excerpt.slice(0, maxExcerptLength)}...`
			: post.post_excerpt;

	if (post.post_type === 'post' && post.external_link) {
		return (
			<a
				href={post.external_link}
				target={target}
				className={`card card--wide card--${post.post_type}`}
			>
				{!!post.featured_image && (
					<figure
						className="card__image"
						dangerouslySetInnerHTML={{
							__html: `${post.featured_image}`,
						}}
					/>
				)}
				<div className="card__content">
					<div className="card__type">{post.post_type_label}</div>
					<h3
						className="card__title"
						dangerouslySetInnerHTML={{ __html: sanitizeHtml(post.post_title) }}
					/>
					{'post' === post.post_type && <span className="card__meta">{dateString}</span>}
					{post.post_excerpt && (
						<div
							className="card__excerpt body-small"
							dangerouslySetInnerHTML={{ __html: excerptContent }}
						/>
					)}
					{'undefined' !== typeof postTypes[postType] && (
						<span className="btn-tertiary">View {postTypes[postType]}</span>
					)}
				</div>
			</a>
		);
	}
	if (post.post_type === 'post' && !post.external_link) {
		return (
			<div className={`card card--wide card--${post.post_type}`}>
				{!!post.featured_image && (
					<figure
						className="card__image"
						dangerouslySetInnerHTML={{
							__html: `${post.featured_image}`,
						}}
					/>
				)}
				<div className="card__content">
					<div className="card__type">{post.post_type_label}</div>

					<h3
						className="card__title"
						dangerouslySetInnerHTML={{ __html: sanitizeHtml(post.post_title) }}
					/>
					{'post' === post.post_type && <span className="card__meta">{dateString}</span>}
					{post.post_excerpt && (
						<div
							className="card__excerpt body-small"
							dangerouslySetInnerHTML={{ __html: excerptContent }}
						/>
					)}
				</div>
			</div>
		);
	}
	return (
		<a
			href={post.permalink}
			target={target}
			className={`card card--wide card--${post.post_type}`}
		>
			{!!post.featured_image && (
				<figure
					className="card__image"
					dangerouslySetInnerHTML={{
						__html: `${post.featured_image}`,
					}}
				/>
			)}
			<div className="card__content">
				<div className="card__type">{post.post_type_label}</div>
				<h3
					className="card__title"
					dangerouslySetInnerHTML={{ __html: sanitizeHtml(post.post_title) }}
				/>
				{'insight' === post.post_type && (
					<span
						className="card__meta"
						dangerouslySetInnerHTML={{ __html: sanitizeHtml(authorsString) }}
					/>
				)}
				{'team-member' === post.post_type && (
					<span className="card__meta">{post.tm_title}</span>
				)}
				{'post' === post.post_type && <span className="card__meta">{dateString}</span>}
				{post.post_excerpt && (
					<div
						className="card__excerpt body-small"
						dangerouslySetInnerHTML={{ __html: excerptContent }}
					/>
				)}
				{'undefined' !== typeof postTypes[postType] && (
					<span className="btn-tertiary">View {postTypes[postType]}</span>
				)}
			</div>
		</a>
	);
};

Wide.displayName = 'Wide';

export default Wide;
