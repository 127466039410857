import { ITerm } from '../types';

const formatAuthors = (authors: Array<ITerm>) => {
	let formattedAuthors = 'By';
	authors.forEach((author, i) => {
		if (0 === i) {
			// is first
			formattedAuthors = formattedAuthors + ` <strong>${author.name}</strong>`;
		} else if (authors.length - 1 === i) {
			// is last
			formattedAuthors = formattedAuthors + ` and <strong>${author.name}</strong>`;
		} else {
			formattedAuthors = formattedAuthors + `, <strong>${author.name}</strong>`;
		}
	});
	return formattedAuthors;
};

export default formatAuthors;
