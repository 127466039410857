import * as React from 'react';
import sanitizeHtml from 'sanitize-html';
import formatAuthors from '../../utils/formatAuthors';
import formatDate from '../../utils/formatDate';
import { IPost } from '../../types';

interface IProps {
	post: IPost;
}

const Default = ({ post }: IProps) => {
	const date = new Date(post.post_date);
	const dateString = formatDate(date);
	const authorsString = formatAuthors(post.authors);
	const maxExcerptLength = 200;
	const target = 'white_paper' === post.post_type ? '_blank' : '_self';

	return (
		<a href={post.permalink} target={target} className={`card card--${post.post_type}`}>
			{!!post.featured_image && (
				<figure
					className="card__image"
					dangerouslySetInnerHTML={{
						__html: `<div class="card__type">${post.post_type_label}</div>${post.featured_image}`,
					}}
				/>
			)}
			<h3
				className="card__title"
				dangerouslySetInnerHTML={{ __html: sanitizeHtml(post.post_title) }}
			/>
			{'insight' === post.post_type && (
				<span
					className="card__meta"
					dangerouslySetInnerHTML={{ __html: sanitizeHtml(authorsString) }}
				/>
			)}
			{'team-member' === post.post_type && (
				<span className="card__meta">{post.tm_title}</span>
			)}
			{'post' === post.post_type && <span className="card__meta">{dateString}</span>}
			<p className="card__excerpt body-small">
				{post.post_excerpt.length > maxExcerptLength
					? `${post.post_excerpt.slice(0, maxExcerptLength)}...`
					: post.post_excerpt}
			</p>
			{'spotlight' === post.post_type && (
				<>
					<span className="btn-tertiary">View Collection</span>
					<div className="spotlight-decor"></div>
				</>
			)}
		</a>
	);
};

Default.displayName = 'Default';

export default Default;
