import * as React from 'react';
import { useSettingsContext } from '../contexts/settings';
import { IPost } from '../types';
import Default from './cards/Default';
import Wide from './cards/Wide';

interface IProps {
	posts: Array<IPost>;
	setLoadingHeight?: (height: number | null) => void;
}

const PostsGrid = ({ posts, setLoadingHeight }: IProps) => {
	const tableRef = React.useRef<HTMLDivElement | null>(null);
	const settings = useSettingsContext();

	const CardType = true === settings.wideCardType ? Wide : Default;

	React.useEffect(() => {
		if (setLoadingHeight) {
			if (null !== tableRef.current && tableRef.current.offsetHeight) {
				setLoadingHeight(tableRef.current.offsetHeight);
			} else {
				setLoadingHeight(null);
			}
		}
	}, [posts]);

	if (posts.length) {
		return (
			<div
				ref={tableRef}
				className={`pfa-posts-grid pfa-posts-grid--${settings.postType} ${
					true === settings.wideCardType
						? 'pfa-posts-grid--wide'
						: 'pfa-posts-grid--default'
				}`}
			>
				{posts.map((post) => {
					switch (post.post_type) {
						// You can use this to add different cards.
						default: {
							return <CardType post={post} key={post.ID} />;
						}
					}
				})}
			</div>
		);
	}

	return <></>;
};

PostsGrid.displayName = 'PostsGrid';

export default PostsGrid;
