import * as React from 'react';
import { usePostsContext } from '../contexts/posts';
import Loading from '../widgets/Loading';

interface IProps {
	children: React.ReactNode;
}

const Posts = ({ children }: IProps) => {
	const {
		posts,
		maxNumPages,
		foundPosts,
		page,
		setPage,
		loading,
		loadingHeight,
		setLoadingHeight,
	} = usePostsContext();
	const [isLoading, setIsLoading] = React.useState(true);

	// Create a minimum load time to prevent flickering.
	const lastLoading = React.useRef<boolean | Date>(false);
	React.useEffect(() => {
		const minLoader = 500;

		if (loading) {
			lastLoading.current = new Date();
			setIsLoading(true);
		} else if ('boolean' !== typeof lastLoading.current) {
			const now = new Date();
			const diff = now.getTime() - lastLoading.current.getTime();

			if (diff > minLoader) {
				setIsLoading(false);
			} else {
				setTimeout(() => {
					setIsLoading(false);
				}, minLoader - diff);
			}
		}
	}, [loading]);

	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child as React.ReactElement, {
				posts,
				maxNumPages,
				foundPosts,
				page,
				setPage,
				loading,
				loadingHeight,
				setLoadingHeight,
				isLoading,
				setIsLoading,
			});
		}
		return child;
	});

	return (
		<Loading isLoading={isLoading} height={loadingHeight}>
			<div className="pfa-posts">
				<div className="container-fluid">{childrenWithProps}</div>
			</div>
		</Loading>
	);
};

Posts.displayName = 'Posts';

export default Posts;
